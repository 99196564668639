@use "sass:math";

$message-line-height: 20pt !default;
$message-padding-vertical: 5px !default;
$message-height: $message-line-height + 2 * $message-padding-vertical;
$message-padding-horizontal: math.div($message-height, 2);
$message-border-radius: $message-padding-horizontal;
$bot-quickreply-horizontal-padding: $message-border-radius;
$avatar-border-radius: $message-border-radius;
$message-input-margin: 5px !default;
$message-input-padding: 5px !default;
$message-font-size: 12pt !default;
$message-input-height: $message-font-size + 2 * $message-input-padding;
$send-button-size: $message-input-height + 2 * $message-input-margin;
$input_border_radius: $send-button-size;
$chat-window-border-radius: 10px !default;
