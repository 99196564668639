import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LearnerInfoResponseDto} from '../../../../../micromate-learn-lib/src/lib/services/rest/api-types/learnerInfoResponseDto';

export class ZitadelUserMapper {

    public static map(zitadelUser: object, learnerInfo: LearnerInfoResponseDto): User {
        // We get null values from zitadel for empty fields, we do want to remove them
        // eslint-disable-next-line no-null/no-null
        const entries = Object.entries(zitadelUser).filter(([, value]) => value !== null);
        const user = Object.fromEntries(entries) as User;

        const roles = user['urn:zitadel:iam:org:project:roles'] as { [key: string]: string; };
        user.roles = Object.keys(roles);
        user.uiLocale = learnerInfo.learnLocale;
        user.learnerLocale = learnerInfo.learnerLocale;

        return user;
    }

}
