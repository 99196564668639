import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {LearnAuthService} from '../../core/authentication/learn-auth.service';
import {filter, map, switchMap} from 'rxjs/operators';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';
import {StudyProgress} from '../../../../../micromate-learn-lib/src/lib/types/StudyProgress';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {StudyProgressRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/study-progress-ressource.service';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {LearnLearningPackageService} from '../../../../../micromate-learn-lib/src/lib/services/learn-learning-package.service';
import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    selector: 'mm-learning-study-progress',
    templateUrl: './learning-study-progress.component.html',
    styleUrls: ['./learning-study-progress.component.scss']
})
export class LearningStudyProgressComponent implements OnInit, OnDestroy {
    public studyProgresses: BehaviorSubject<StudyProgress[]> = new BehaviorSubject<StudyProgress[]>(undefined);
    public studyProgresses$ = this.studyProgresses.asObservable();
    public paramsSubscription: Subscription;
    private learnerId: string;
    private openInConversation: boolean;

    constructor(private activatedRoute: ActivatedRoute,
                private studyProgressService: StudyProgressRessource,
                @Inject(LIB_AUTH_SERVICE_TOKEN) public authService: LearnAuthService,
                private authUntilUserRoleService: AuthUntilUserRoleService,
                private router: Router,
                private eventRecorderService: EventRecorderService,
                private learnLearningPackageService: LearnLearningPackageService) {
    }

    public ngOnInit(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.pipe(
            map(params => {
                this.learnerId = params.get('learnerid') ?? undefined;
                this.openInConversation = params.get('openInConversation') === 'true';
            }),
            switchMap(() => this.authService.authentication$),
            filter((authentication: User) => authentication !== undefined))
            .subscribe(async (authentication: User) => {
                await this.getStudyProgress(authentication);
                await this.recordTabEvent(authentication);
            });
    }

    public ngOnDestroy(): void {
        if (this.paramsSubscription !== undefined) {
            this.paramsSubscription.unsubscribe();
        }
    }

    private async getStudyProgress(authentication: User): Promise<void> {
        if (this.learnerId === undefined) {
            const zitadelId = authentication.sub;
            const studyProgressesData = await this.studyProgressService.getLearnerStudyProgressForLearn(undefined, zitadelId);
            this.studyProgresses.next(studyProgressesData === undefined ? [] : studyProgressesData);
        } else {
            const isAdmin = await this.authUntilUserRoleService.assertAdmin().toPromise();
            if (!isAdmin) {

                void this.router.navigate(['/learn']);
            } else {
                const studyProgressesData = await this.studyProgressService.getAdminLearnerStudyProgressForLearn(this.learnerId);
                this.studyProgresses.next(studyProgressesData === undefined ? [] : studyProgressesData);
            }
        }
    }

    private async recordTabEvent(authentication: User): Promise<void> {
        await this.eventRecorderService.recordTabOpenedEventForLearn(this.learnerId, authentication.sub, TabEventType.TabLearningProgressOpened, this.eventRecorderService.createTabOpenEventPayloadForLearn(this.openInConversation));
    }

    public async learnPackage(learningPackageId: string): Promise<void> {
        await this.learnLearningPackageService.learn(learningPackageId);
    }
}
