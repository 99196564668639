import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {LearnerInfoResponseDto} from './api-types/learnerInfoResponseDto';
import {Injectable} from '@angular/core';
import {LearnerUpdateRequestDto} from './api-types/learner-update-request.dto';
import {EnvironmentService} from '../EnvironmentService';

@Injectable({
    providedIn: 'root'
})
export class LearnerInfoResource {
    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public async loadLearnerData(): Promise<LearnerInfoResponseDto> {
        return firstValueFrom(this.http.get<LearnerInfoResponseDto>(`${this.environmentService.environment.serverUrl}/api/learner`));
    }

    public async saveLearnerData(data: LearnerUpdateRequestDto): Promise<void> {
        return firstValueFrom(this.http.post<void>(`${this.environmentService.environment.serverUrl}/api/learner`, data));
    }
}

