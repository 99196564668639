@import '../../theme/micromate-theme';

$chat-hide-bot-avatar: true;

$color-primary: var(--micromate-color-secondary, $color-orange-light);
@import '../../../../../../node_modules/botato-chat-component-style/color-theme/color-one';

$bot-message-bg-color: $color-white;
$bot-message-border: 2px solid $color-primary;
$bot-message-color: $color-black;
$message-link-color: $color-black;
$message-link-hover-color: $color-orange-dark;
$chat-message-width: var(--micromate-chat-message-width, 95%);

@import '../../../../../../node_modules/botato-chat-component-style/layout-theme/round';
@import '../../../../../../node_modules/botato-chat-component-style/base/chat';
@import '../../../../../../node_modules/botato-chat-component-style/components/quickreplies';


.pa-chat {
    margin-bottom: 8px;
}

.micromate-message-content {
    overflow: hidden;
    height: 100%;
    background-color: $bot-message-bg-color;
    color: $bot-message-color;
    border: $bot-message-border;
    font-size: 12pt;
    line-height: 20pt;
    padding: 5px 13.75pt;

    ul {
        list-style-type: disc;
        margin-left: 34px;
    }

    ol {
        list-style-type: decimal;
        margin-left: 34px;
    }

    div {
        line-height: 20pt;
    }

    .app-quiz-question {
        display: block;
        margin-bottom: 8px;
    }

    .micromate-quiz-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 10pt;
        margin-top: 4px;
        padding-bottom: 16px;
        line-height: normal;
        align-items: baseline;

        .micromate-quiz-learning-package-name {
            margin-right: 15px;
        }

        .mm-quiz-question-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 18px;
            align-items: center;

            .mm-quiz-question-in-session {
                min-width: 21px;
            }

            .mm-quiz-question-info-feedback-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
    }

    .micromate-quiz-question-additional-message {
        padding-bottom: 16px;
        font-size: $chat-message-font-size;
    }

    .micromate-quiz-question {
        padding-bottom: 8px;
        font-size: $chat-message-font-size;
    }

    .micromate-quiz-hint {
        font-size: 10pt;
        text-align: center;
        color: $color-primary;
        line-height: normal;
        margin-bottom: $micromate-margin-small;

        span {
            color: $color-primary;
        }
    }

    .micromate-true-false-quiz-answers {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        align-items: baseline;

        .micromate-true-false-quiz-answer {
            border: 1px solid $color-primary;
            color: $color-black;
            border-radius: 4px;
            padding: 8px;
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;
            text-align: center;
            cursor: pointer;

            &:hover {
                box-shadow: $box-shadow;
            }

            &.micromate-true-false-quiz-answer-false {
                margin-right: 8px
            }

            &.micromate-true-false-quiz-answer-true {
                margin-left: 8px;
            }

            &.micromate-true-false-quiz-answer-letter-deactivated {
                border: none;
                background-color: $color-grey-lighter;
                color: $bot-message-color;
                font-weight: normal;
                cursor: default;

                &:hover {
                    box-shadow: unset;
                }
            }

            &.micromate-true-false-quiz-answer-correct {
                background-color: $color-green-light;
            }

            &.micromate-true-false-quiz-answer-incorrect {
                background-color: $color-red-light;
            }

            &.micromate-true-false-quiz-answer-answered {
                font-weight: bold;
            }
        }
    }

    .micromate-multiple-choice-quiz-answers {
        list-style-type: none;
        margin-left: 0;

        .micromate-multiple-choice-quiz-answer {
            ul {
                list-style-type: disc;
                margin-left: 34px;
            }

            ol {
                list-style-type: decimal;
                margin-left: 34px;
            }

            .micromate-multiple-choice-quiz-answer-data {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 8px;
                cursor: pointer;
                user-select: none;
            }

            .micromate-multiple-choice-quiz-answer-data input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .micromate-checkmark {
                position: absolute;
                top: 4px;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: $color-white;
                border: 1px solid $color-primary;
            }

            .micromate-multiple-choice-quiz-answer-data input:checked ~ .micromate-checkmark {
                background-color: $color-primary;
            }

            .micromate-checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .micromate-multiple-choice-quiz-answer-data input:checked ~ .micromate-checkmark:after {
                display: block;
            }

            .micromate-multiple-choice-quiz-answer-data .micromate-checkmark:after {
                left: 6px;
                top: 2px;
                width: 7px;
                height: 12px;
                border: solid $color-white;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }


            .micromate-multiple-choice-quiz-answer-text {
                margin-left: 8px;
            }
        }

    }

    .micromate-question-button {
        border: 1px solid $color-primary;
        color: $color-black;
        border-radius: 4px;
        padding: 2px;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;
        width: fit-content;
        margin-bottom: 16px;
        cursor: pointer;

        &:hover {
            box-shadow: $box-shadow;
        }
    }

    .micromate-question-button-deactivated {
        border: 1px solid $color-grey;
        color: $color-grey;
        cursor: not-allowed;

        &:hover {
            box-shadow: none;
        }
    }

    .micromate-choice-quiz-answers {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        align-items: baseline;

        .micromate-choice-quiz-answers-letter {
            border: 1px solid $color-primary;
            color: $color-black;
            border-radius: 4px;
            padding: 2px;
            padding-left: 16px;
            padding-right: 16px;
            cursor: pointer;
            text-align: center;
            white-space: nowrap;

            &:hover {
                box-shadow: $box-shadow;
            }
        }

        .micromate-choice-quiz-answers-text {
            margin-left: 16px;
        }

        &.micromate-choice-quiz-answers-correct {
            background-color: $color-green-light;
        }

        &.micromate-choice-quiz-answers-incorrect {
            background-color: $color-red-light;
        }

        .micromate-choice-quiz-answers-answered {
            font-weight: bold;
        }
    }

    .micromate-choice-quiz-answers-deactivated {
        margin-bottom: 8px;
    }

    .micromate-choice-quiz-answers-update {
        .micromate-choice-quiz-answers-letter {
            border: none;
            color: $color-black;
            font-weight: normal;
            cursor: default;
            white-space: nowrap;

            &:hover {
                box-shadow: unset;
            }
        }

        .micromate-choice-quiz-answers-answered {
            font-weight: bold;
        }
    }

    a {
        color: $message-link-color;
        text-decoration: $message-link-text-decoration;

        &:visited, &:link {
            color: $message-link-color;
        }

        &:hover, &:active {
            color: $message-link-hover-color;
        }
    }

    .micromate-profile-link {
        background-color: $color-yellow-light;
        border-radius: 4px;
        color: $color-black;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 8px;
        width: fit-content;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 8px;

        .micromate-profile-link-icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        &:hover {
            box-shadow: $box-shadow;
        }
    }

    .app-quiz-media-explanation {
        display: block;
        margin-top: 8px;
    }

    .micromate-question-explanation-text {
        margin-top: 8px;
    }

    &.micromate-message-content-reflection {
        background-position-x: right;
        background-position-y: bottom;
        background-repeat: no-repeat;
        background-size: 400px;
    }

    .app-quiz-question-background-text-margin {

        .micromate-quiz-question-additional-message {
            margin-right: 50px;
        }

        .micromate-quiz-question {
            margin-right: 50px;
        }

        .micromate-quiz-media {
            margin-right: 50px;
        }
    }

    .micromate-message-reflection-single-item {
        width: 48vw;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .micromate-message-reflection-single-item-content-text {
            flex: 1;
        }
    }

    .micromate-message-reflection-carousel {
        min-height: 50px;
        height: auto;
        width: 50vw;
        position: relative;

        .micromate-message-reflection-carousel-item {
            width: 100%;
            position: absolute;
            left: calc(-100% - 20px);
            transition: left 0.3s;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;

            &.micromate-message-reflection-carousel-item-active {
                left: 0;

                & + .micromate-message-reflection-carousel-item {
                    left: calc(100% + 20px);
                }
            }
        }

        .micromate-message-reflection-carousel-item-resize-element {
            visibility: hidden;
            width: 100%;
            top: 0;
            left: 0;
            position: relative;
            padding: 14px;
        }


        .micromate-message-reflection-carousel-button {
            position: absolute;
            height: 30px;
            width: 30px;
            top: calc(50% - 15px);
            left: 0;
            border: none;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-darkviolet;
            z-index: 2;

            &:hover {
                box-shadow: $box-shadow;
            }

            .micromate-message-reflection-carousel-button-icon {
                fill: white;
                bottom: 12px;
                right: 12px;
                cursor: pointer;
                width: 28px;
                height: 28px;
            }

            &.micromate-message-reflection-carousel-button-next {
                left: auto;
                right: 0;
            }

        }
    }

    .micromate-message-reflection-carousel-item-content-text {
        text-align: center;
        font-style: italic;
        background-color: $color-grey-lighter;
        width: 100%;
        height: 100%;
        margin: $micromate-margin-small;
        border-radius: $micromate-margin-small;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $micromate-margin-small;
    }
}


.micromate-message-content-corner-fix {
    border-top-left-radius: 13.75pt;
}

.micromate-message-content-reflection-explanation {
    padding-right: $micromate-margin-small;
    padding-left: $micromate-margin-small;
    border-top-left-radius: 0;
}

@container (max-width: #{$mobile-width}) {
    .micromate-message-content {
        .micromate-message-reflection-carousel {
            width: 70vw;
        }
    }
}
