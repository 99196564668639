import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LearnerIdQueryPrams} from './LearnerIdQueryPrams';
import {StudyProgress} from '../../types/StudyProgress';
import {EnvironmentService} from '../EnvironmentService';

@Injectable({
    providedIn: 'root'
})
export class StudyProgressRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public async getLearnerStudyProgress(msteamsId: string): Promise<StudyProgress[]> {
        return this.http.get<StudyProgress[]>(
            `${this.environmentService.environment.serverUrl}/api/profile/study-progress?msteamsid=${msteamsId}`).toPromise();
    }

    public async getLearnerStudyProgressForLearn(learnerId: string, zitadelId: string): Promise<StudyProgress[]> {
        return this.http.get<StudyProgress[]>(`${this.environmentService.environment.serverUrl}/api/learn/profile/study-progress?${LearnerIdQueryPrams.create(learnerId, zitadelId)}`).toPromise();
    }

    public async getAdminLearnerStudyProgressForLearn(learnerId: string): Promise<StudyProgress[]> {
        return this.http.get<StudyProgress[]>(`${this.environmentService.environment.serverUrl}/api/learn/admin/study-progress?learnerid=${learnerId}`).toPromise();
    }
}
