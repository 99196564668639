import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {LearnAuthService} from '../../core/authentication/learn-auth.service';
import {filter, map, switchMap} from 'rxjs/operators';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';
import {LearnerBadge} from '../../../../../micromate-learn-lib/src/lib/types/LearnerBadge';
import {BadgesRessource} from '../../../../../micromate-learn-lib/src/lib/services/rest/badges-ressource.service';
import {EventRecorderService} from '../../../../../micromate-learn-lib/src/lib/services/event/event-recorder.service';
import {TabEventType} from '../../../../../micromate-learn-lib/src/lib/services/event/TabEventType';
import {User} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/User';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Component({
    selector: 'mm-learning-badge',
    templateUrl: './learning-badge.component.html',
    styleUrls: ['./learning-badge.component.scss']
})
export class LearningBadgeComponent implements OnInit, OnDestroy {
    public badges: BehaviorSubject<LearnerBadge[]> = new BehaviorSubject<LearnerBadge[]>(undefined);
    public badges$ = this.badges.asObservable();
    public paramsSubscription = new Subscription();
    public openInConversation: boolean;

    private learnerId: string;

    constructor(private activatedRoute: ActivatedRoute,
                private badgesService: BadgesRessource,
                @Inject(LIB_AUTH_SERVICE_TOKEN) public authService: LearnAuthService,
                private authUntilUserRoleService: AuthUntilUserRoleService,
                private router: Router,
                private eventRecorderService: EventRecorderService) {
    }

    public ngOnInit(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.pipe(
            map(params => {
                this.learnerId = params.get('learnerid') ?? undefined;
                this.openInConversation = params.get('openInConversation') === 'true';
            }),
            switchMap(() => this.authService.authentication$),
            filter((authentication: User) => authentication !== undefined))
            .subscribe(async (authentication: User) => {
                await this.getBadges(authentication);
                await this.recordTabEvents(authentication);
            });
    }

    public ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

    private async getBadges(authentication: User): Promise<void> {
        if (this.learnerId === undefined) {
            const zitadelId = authentication.sub;
            const badgesPromise = this.badgesService.getLearnerBadgesForLearn(undefined, zitadelId);
            this.badges.next(await badgesPromise);
        } else {
            const isAdmin = await this.authUntilUserRoleService.assertAdmin().toPromise();
            if (!isAdmin) {

                void this.router.navigate(['/learn']);
            } else {
                const badgesPromise = this.badgesService.getAdminLearnerBadgesForLearn(this.learnerId);
                this.badges.next(await badgesPromise);
            }
        }
    }

    private async recordTabEvents(authentication: User): Promise<void> {
        await this.eventRecorderService.recordTabOpenedEventForLearn(this.learnerId, authentication.sub, TabEventType.TabBadgeOpened, this.eventRecorderService.createTabOpenEventPayloadForLearn(this.openInConversation));
    }

}
