import {Inject, Injectable} from '@angular/core';
import {RegistrationRequestDto} from '../core/rest/api-types/RegistrationRequestDto';
import {RegistrationRessource} from '../core/rest/registration-ressource.service';
import {RegistrationComponentFormDataValues} from './RegistrationComponentFormDataValues';
import {RegistrationLearnerDataResponseDto} from '../core/rest/api-types/RegistrationLearnerDataResponseDto';
import {RegistrationLearnerDataExchangeRequestDto} from '../core/rest/api-types/RegistrationLearnerDataExchangeRequestDto';
import {LearnAuthService} from '../core/authentication/learn-auth.service';
import {InvitationStrategy} from './strategies/InvitationStrategy';
import {SelfRegistrationWithOrganisationStrategy} from './strategies/SelfRegistrationWithOrganisationStrategy';
import {SelfRegistrationWithoutOrganisationStrategy} from './strategies/SelfRegistrationWithoutOrganisationStrategy';
import {RegistrationStrategy} from './strategies/RegistrationStrategy';
import {OrganizationNameForCodeResponseDto} from './organizationNameForCodeResponseDto';
import {SelfRegistrationResponseDto} from '../core/rest/self-registration-response.dto';
import {SelfRegistrationRequestDto} from '../core/rest/api-types/SelfRegistrationRequestDto';
import {LIB_AUTH_SERVICE_TOKEN} from '../../../../micromate-learn-lib/src/lib/components/chatbot/libAuthService';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    constructor(private registrationRessource: RegistrationRessource,
                @Inject(LIB_AUTH_SERVICE_TOKEN) private authService: LearnAuthService) {
    }

    public async registerLearner(userId: string, formData: RegistrationComponentFormDataValues): Promise<void> {
        const locale = formData.locale;
        const firstName = formData.firstName.trim();
        const lastName = formData.lastName.trim();
        const password = formData.password.trim();
        const registrationLearner = new RegistrationRequestDto(firstName, lastName, password, locale, userId);

        await this.registrationRessource.register(registrationLearner);
        this.authService.setActiveUser(formData.email);
    }

    public async loadLearnerData(userId: string): Promise<RegistrationLearnerDataResponseDto> {
        return this.registrationRessource.loadLearnerData(new RegistrationLearnerDataExchangeRequestDto(userId));
    }

    public resolveRegistrationTypeFromParameters(userId: string, organizationCode: string): RegistrationStrategy {
        if (userId === undefined && organizationCode === undefined) {
            return new SelfRegistrationWithoutOrganisationStrategy(this);
        }

        if (userId === undefined && organizationCode !== undefined) {
            return new SelfRegistrationWithOrganisationStrategy(this);
        }

        if (userId !== undefined && organizationCode === undefined) {
            return new InvitationStrategy(this);
        }

        return undefined;
    }

    public async selfRegisterLearnerWithoutOrganisation(formData: RegistrationComponentFormDataValues): Promise<SelfRegistrationResponseDto> {
        const locale = formData.locale;
        const firstName = formData.firstName.trim();
        const lastName = formData.lastName.trim();
        const password = formData.password.trim();
        const email = formData.email.trim();
        const registrationLearner = new SelfRegistrationRequestDto(firstName, lastName, password, locale, email);
        return this.registrationRessource.selfRegister(registrationLearner);
    }

    public async selfRegisterLearnerWithOrganisation(formData: RegistrationComponentFormDataValues, orgCode: string): Promise<SelfRegistrationResponseDto> {
        const locale = formData.locale;
        const firstName = formData.firstName.trim();
        const lastName = formData.lastName.trim();
        const password = formData.password.trim();
        const email = formData.email.trim();

        const registrationLearner = new SelfRegistrationRequestDto(firstName, lastName, password, locale, email, orgCode);
        return this.registrationRessource.selfRegister(registrationLearner);
    }

    public async exchangeOrgCodeToOrganizationName(orgCode: string): Promise<OrganizationNameForCodeResponseDto> {
        return this.registrationRessource.exchangeOrgCodeToOrganizationName(orgCode);
    }
}

