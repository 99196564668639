import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LearnerResourceSelectionResponseDto} from './learner-resource-selection-response.dto';
import {EnvironmentService} from '../../../../../../micromate-learn-lib/src/lib/services/EnvironmentService';

@Injectable({providedIn: 'root'})
export class AiRessource {
    constructor(private http: HttpClient, private environmentService: EnvironmentService,) {
    }

    public getResourceWithSelection(resourceId: string, selectionId: string, loadAsMicromateHtml: boolean): Observable<LearnerResourceSelectionResponseDto> {
        return this.http.get<LearnerResourceSelectionResponseDto>(`${this.environmentService.environment.serverUrl}/api/learn/resource-viewer/resources/${resourceId}/selections/${selectionId}`, {params: {loadAsMicromateHtml: loadAsMicromateHtml.toString()}});
    }

    public getResource(resourceId: string, loadAsMicromateHtml: boolean): Observable<LearnerResourceSelectionResponseDto> {
        return this.http.get<LearnerResourceSelectionResponseDto>(`${this.environmentService.environment.serverUrl}/api/learn/resource-viewer/resources/${resourceId}`, {params: {loadAsMicromateHtml: loadAsMicromateHtml.toString()}});
    }
}
