import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LearnerIdQueryPrams} from './LearnerIdQueryPrams';
import {LearnerBadge} from '../../../../../micromate-learn-lib/src/lib/types/LearnerBadge';
import {EnvironmentService} from '../EnvironmentService';

@Injectable({
    providedIn: 'root'
})
export class BadgesRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public async getLearnerBadges(msteamsId: string): Promise<LearnerBadge[]> {
        return this.http.get<LearnerBadge[]>(`${this.environmentService.environment.serverUrl}/api/profile/badges?msteamsid=${msteamsId}`).toPromise();
    }

    public async getLearnerBadgesForLearn(learnerId: string, zitadelId: string): Promise<LearnerBadge[]> {
        return this.http.get<LearnerBadge[]>(`${this.environmentService.environment.serverUrl}/api/learn/profile/badges?${LearnerIdQueryPrams.create(learnerId, zitadelId)}`).toPromise();
    }

    public async getAdminLearnerBadgesForLearn(learnerId: string): Promise<LearnerBadge[]> {
        return this.http.get<LearnerBadge[]>(`${this.environmentService.environment.serverUrl}/api/learn/admin/badges?learnerid=${learnerId}`).toPromise();
    }
}
