/*----------------------------
Input Variables:
$color-primary
----------------------------*/

$color-primary-in-project: $color-primary;

/*----------------------------*/

$color-white: white;
$color-black: black;
$color-grey: #CCC;
$color-dark-grey: #0e0e0e;

$user-message-bg-color: $color-white;
$user-message-color: $color-dark-grey;
$user-message-border: solid 1px $color-grey;

$bot-message-bg-color: $color-primary-in-project;
$bot-message-color: $color-white;
$bot-message-border: none;

$message-link-color: $color-white;
$message-link-hover-color: $color-dark-grey;
$message-link-text-decoration: underline;

$bot-quickreply-bg-color: $color-white;
$bot-quickreply-color: $color-dark-grey;
$bot-quickreply-border-color: $color-grey;
$bot-quickreply-focus-bg-color: $color-white;
$bot-quickreply-focus-color: $color-dark-grey;
$bot-quickreply-hover-bg-color: $color-white;

$selection-list-description-color: $color-primary-in-project;
$selection-list-select-bg-color: $color-primary-in-project;
$selection-list-select-color: $color-white;

$message-input-border: solid 2px $color-grey;
$message-input-color: $color-dark-grey;
$message-input-bg-color: $color-white;
$message-input-send-button-disabled-color: $color-grey;
$message-input-send-button-color: $color-primary-in-project;
$message-placeholder-color: $color-grey;

$meta-color: $color-dark-grey;

$chat-window-header-background-color: $color-primary-in-project;
$chat-window-header-font-color: $color-white;

$chat-window-background-color: white;

$file-upload-add-button-font-color: $color-dark-grey;
$file-upload-add-button-background-color: $color-white;
$file-upload-add-button-border-color: $color-grey;
$file-upload-send-button-color: $color-primary-in-project;

$autocomplete-overlay-background: $color-white;
$autocomplete-item-background: $color-grey;
$autocomplete-overlay-bar-close: $color-grey;

$bot-multi-selection-icon-color: green;

$meta-letter-count-full-color: #EE0000;
$error-color: #EE0000;
$connection-problem-bg-color: #EE0000;
$connection-problem-color: white;

$file-upload-add-button-drop-font-color: grey;
$file-upload-add-button-drop-background-color: white;
$file-upload-add-button-drop-border-color: grey;
$file-upload-error-background-color: #ffa3a3;
$file-upload-error-font-color: black;

$chat-widget-maximized-shadow-color: black;
$chat-window-shadow: -1px -1px 10px -1px rgba(0, 0, 0, 0.85);
$notification-shadow: inset 0 0 5px 0px rgba(0, 0, 0, 0.5);

$bot-link-bg-color: $color-primary-in-project;
$bot-link-color: $color-white;
