import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {LearnerProfile} from '../../types/LearnerProfile';
import {FormControl, FormGroup} from '@angular/forms';
import {take} from 'rxjs/operators';
import {ProfilePseudonymService} from './profile-pseudonym.service';
import {PseudonymSettings} from './PseudonymSettings';

export interface ProfileLocaleFormGroup {
    locale: FormControl<string>;
}

@Component({
    selector: 'mm-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
    @Input()
    public profileData: Observable<LearnerProfile>;

    @Input()
    public locale: Observable<string>;

    @Input()
    public darkMode: boolean;

    @Input()
    public openAsCard: boolean = true;

    @Input()
    public doNotAllowToEdit: boolean = false;

    @Input()
    public changDetectionTriggered: boolean = false;

    @Input()
    public profileUpdated: Observable<void>;

    @Output()
    public generatePseudonym = new EventEmitter();

    @Output()
    public savePseudonymSettings = new EventEmitter<PseudonymSettings>();
    @Output() changedMonthlyReport = new EventEmitter<boolean>();

    @Output()
    public saveProfileData = new EventEmitter<LearnerProfile>();

    public loadedLocale: string;

    public localeEditorActive: boolean = false;
    public pseudonymEditorActive: boolean = false;
    public usePseudonymFormControl = new FormControl<boolean>(false);
    public useMonthlyReportFormControl = new FormControl<boolean>(true);
    public pseudonym: string;
    private profileDataSubscription = new Subscription();
    private localeSubscription = new Subscription();
    private subscriptionGeneratingPseudonym = new Subscription();
    public pseudonymValueChange: boolean = false;
    public localeValueChanged: boolean = false;
    private initialLocalValue: string;

    public localeFormData = new FormGroup<ProfileLocaleFormGroup>({
        locale: new FormControl<string>('')
    });

    constructor(private ref: ChangeDetectorRef,
                public profilePseudonymService: ProfilePseudonymService) {
    }

    public ngOnInit(): void {
        if (this.doNotAllowToEdit) {
            this.useMonthlyReportFormControl.disable();
        }
        this.profileDataSubscription = this.profileData.subscribe(profileData => {
            if (profileData !== undefined) {
                this.usePseudonymFormControl.setValue(profileData.usePseudonym);
                this.useMonthlyReportFormControl.setValue(profileData.sendMonthlyLearningReport);
                this.pseudonym = profileData.pseudonym;
                this.detectChanges();

                this.usePseudonymFormControl.valueChanges.subscribe(() => {
                    this.pseudonymValueChange = true;
                    this.detectChanges();
                });

                this.subscribeGeneratingPseudonym();
            }
        });

        this.localeSubscription = this.locale.subscribe(locale => {
            this.localeFormData.controls.locale.setValue(locale);
            this.initialLocalValue = locale;
            this.loadedLocale = locale;

            this.detectChanges();

            this.localeFormData.valueChanges.subscribe(() => {
                this.localeValueChanged = this.initialLocalValue !== this.localeFormData.controls.locale.getRawValue();
                this.detectChanges();
            });
        });
    }

    private subscribeGeneratingPseudonym(): void {
        this.subscriptionGeneratingPseudonym = this.profilePseudonymService.generatingPseudonym$.subscribe(status => {
            const newPseudonym = this.profilePseudonymService.getNewPseudonym();
            if (status === false && newPseudonym !== undefined) {
                this.pseudonym = newPseudonym;
                this.pseudonymValueChange = true;
                this.detectChanges();
            }
        });
    }

    public ngOnDestroy(): void {
        this.profileDataSubscription.unsubscribe();
        this.subscriptionGeneratingPseudonym.unsubscribe();
        this.localeSubscription.unsubscribe();
        this.cancelProfilePseudonymEditor();
    }

    public openPseudonymEditor(): void {
        if (!this.pseudonymEditorActive && !this.doNotAllowToEdit) {
            this.pseudonymEditorActive = true;
            this.detectChanges();
        }
    }

    public openLocaleEditor(): void {
        if (!this.localeEditorActive && !this.doNotAllowToEdit) {
            this.localeEditorActive = true;
            this.detectChanges();
        }
    }

    private detectChanges(): void {
        if (this.changDetectionTriggered) {
            this.ref.detectChanges();
        }
    }

    public cancelLocale(): void {
        this.resetLocaleEditor();
        this.detectChanges();
    }

    public cancelPseudonym(): void {
        this.cancelProfilePseudonymEditor();
        this.detectChanges();
    }

    private cancelProfilePseudonymEditor(): void {
        this.profilePseudonymService.cancelPseudonymGeneration();
        this.resetPseudonymEditor();
    }

    public saveLocale: () => Promise<void> = async () => {
        this.localeFormData.disable();
        return new Promise<void>(resolve => {
            this.profileData.pipe(take(1)).subscribe(profileData => {
                profileData.locale = this.localeFormData.controls.locale.getRawValue();
                this.profileUpdated.pipe(take(1)).subscribe(() => {
                    this.resetLocaleEditor();

                    // Set a timeout before resolvign slightly improves the flickering
                    // as it gives the browser the option to execute the close of the edit area
                    setTimeout(() => {
                        resolve();
                    });
                });
                this.saveProfileData.emit(profileData);
            });
        });
    };

    public savePseudonym(): void {
        const pseudonymSettings = new PseudonymSettings(this.usePseudonymFormControl.getRawValue(), this.pseudonym);
        this.savePseudonymSettings.emit(pseudonymSettings);
        this.resetPseudonymEditor();
    }

    private resetPseudonymEditor(): void {
        this.pseudonymEditorActive = false;
        this.pseudonymValueChange = false;
        this.profileData.pipe(take(1)).subscribe(profileData => {
            this.usePseudonymFormControl.setValue(profileData.usePseudonym);
            this.pseudonym = profileData.pseudonym;
        });
        this.profilePseudonymService.reset();
    }

    private resetLocaleEditor(): void {
        this.localeEditorActive = false;
        this.localeValueChanged = false;
        this.localeFormData.enable();
    }

    public generateNewPseudonym(): void {
        this.profilePseudonymService.startPseudonymGeneration();
        this.detectChanges();
        this.generatePseudonym.emit();
    }

    public changeMonthlyReport(): void {
        if (!this.doNotAllowToEdit) {
            this.changedMonthlyReport.emit(this.useMonthlyReportFormControl.getRawValue());
        }
    }
}
