import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RegistrationLearnerDataExchangeRequestDto} from './api-types/RegistrationLearnerDataExchangeRequestDto';
import {RegistrationRequestDto} from './api-types/RegistrationRequestDto';
import {PasswordComplexityPolicyResponseDto} from './api-types/PasswordComplexityPolicyResponseDto';
import {RegistrationLearnerDataResponseDto} from './api-types/RegistrationLearnerDataResponseDto';
import {firstValueFrom} from 'rxjs';
import {OrganizationNameForCodeResponseDto} from '../../registration/organizationNameForCodeResponseDto';
import {SelfRegistrationResponseDto} from './self-registration-response.dto';
import {SelfRegistrationRequestDto} from './api-types/SelfRegistrationRequestDto';
import {EnvironmentService} from '../../../../../micromate-learn-lib/src/lib/services/EnvironmentService';

@Injectable({
    providedIn: 'root'
})
export class RegistrationRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public async loadLearnerData(dto: RegistrationLearnerDataExchangeRequestDto): Promise<RegistrationLearnerDataResponseDto> {
        return firstValueFrom(this.http.post<RegistrationLearnerDataResponseDto>(`${this.environmentService.environment.serverUrl}/api/registration/learner-data-exchange`, dto));
    }

    public async register(registrationLearner: RegistrationRequestDto): Promise<void> {
        return firstValueFrom(this.http.post<void>(`${this.environmentService.environment.serverUrl}/api/registration/registration`, registrationLearner));
    }

    public async selfRegister(registrationLearner: SelfRegistrationRequestDto): Promise<SelfRegistrationResponseDto> {
        return firstValueFrom(this.http.post<SelfRegistrationResponseDto>(`${this.environmentService.environment.serverUrl}/api/registration/self-registration`, registrationLearner));
    }

    public async getPasswordComplexityPolicy(): Promise<PasswordComplexityPolicyResponseDto> {
        return firstValueFrom(this.http.get<PasswordComplexityPolicyResponseDto>(`${this.environmentService.environment.serverUrl}/api/registration/password-complexity-policy`));
    }

    public async exchangeOrgCodeToOrganizationName(orgCode: string): Promise<OrganizationNameForCodeResponseDto> {
        return firstValueFrom(this.http.get<OrganizationNameForCodeResponseDto>(`${this.environmentService.environment.serverUrl}/api/registration/organization-name-for-code`, {
            params: {
                code: orgCode
            }
        }));
    }
}

