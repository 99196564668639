import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EventData} from '../event/EventData';
import {EnvironmentService} from '../EnvironmentService';

@Injectable({
    providedIn: 'root'
})
export class EventRessource {

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
    }

    public async recordEvent(msteamsId: string, eventData: EventData): Promise<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/profile/tracker?msteamsid=${msteamsId}`, eventData, {responseType: 'text'}).toPromise();
    }

    public async recordEventForLearn(zitadelId: string, eventData: EventData): Promise<string> {
        return this.http.post(`${this.environmentService.environment.serverUrl}/api/learn/profile/tracker?zitadelid=${zitadelId}`, eventData, {responseType: 'text'}).toPromise();
    }

}
