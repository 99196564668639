import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BotatoChatManagerService, DelayedRenderedMessageComponent} from 'botato-angular-lib';
import {ResponseItemDataTextService} from '../../response-item-data-text.service';
import {BadgeResponseItemData} from './BadgeResponseItemData';
import {BadgeResponseItemText} from './BadgeResponseItemText';
import {DeactivateMessageService} from '../../deactivate-message.service';
import {Subscription} from 'rxjs';
import {TAB_NAVIGATION_SERVICE, TabNavigationService} from '../../../../services/TabNavigationService';

@Component({
    selector: 'mm-bagde',
    templateUrl: './bagde.component.html',
    styleUrls: ['./bagde.component.scss']
})
export class BagdeComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'Badge';
    public responseItemData: BadgeResponseItemData;
    public text: BadgeResponseItemText;
    public isDeactivated: boolean;
    private subscription: Subscription;
    private tabNavigationService: TabNavigationService;


    constructor(private responseItemDataTextService: ResponseItemDataTextService,
                private botatoChatManagerService: BotatoChatManagerService,
                private deactivateMessageService: DeactivateMessageService,
                injector: Injector) {
        super();
        this.tabNavigationService = injector.get(TAB_NAVIGATION_SERVICE);
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);

        this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

        this.responseItemData = this.message.data.responseItemData as BadgeResponseItemData;
        this.text = this.responseItemDataTextService.create<BadgeResponseItemText>(this.responseItemData.text);
    }

    public sendNextQuestion(): void {
        void this.botatoChatManagerService.sendUserMessage(this.responseItemData.text.nextQuizButtonText, 'quickreply');
    }

    public createProfileText(text: string): string {
        return text.replace('➤  ', '');
    }

    public navigateToBadges(): void {
        this.tabNavigationService.navigateToBadges(true);

    }

    public badgeImageLoaded(): void {
        this.componentRenderingCompleted();
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {

            this.message.data.deactivated = true;
        }
    }
}
