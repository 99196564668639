import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BotatoChatManagerService,
    DelayedRenderedMessageComponent,
    DialogViewModelCreatorService
} from 'botato-angular-lib';
import {ResponseItemDataTextService} from '../../response-item-data-text.service';
import {ExplanationResponseItemData} from './ExplanationResponseItemData';
import {ExplanationResponseItemText} from './ExplanationResponseItemText';
import {Subscription} from 'rxjs';
import {DeactivateMessageService} from '../../deactivate-message.service';
import {take} from 'rxjs/operators';
import {EnvironmentService} from '../../../../services/EnvironmentService';

@Component({
    selector: 'mm-explanation-card',
    templateUrl: './explanation-card.component.html'
})
export class ExplanationCardComponent extends DelayedRenderedMessageComponent implements OnInit, OnDestroy {

    public static TYPE = 'QuizExplanation';
    public responseItemData: ExplanationResponseItemData;
    public text: ExplanationResponseItemText;
    public isDeactivated: boolean;
    public isExplanationOpen: boolean;
    public hasExplanation: boolean;
    public hasMedia: boolean;
    public micromateManageUrl: string;
    private subscription: Subscription;
    private dialogViewModelCreatorSubscription = new Subscription();

    constructor(private responseItemDataTextService: ResponseItemDataTextService,
                private botatoChatManagerService: BotatoChatManagerService,
                private deactivateMessageService: DeactivateMessageService,
                private dialogViewModelCreatorService: DialogViewModelCreatorService,
                private environmentService: EnvironmentService) {
        super();
        this.micromateManageUrl = environmentService.environment.manageUrl;
    }

    public ngOnDestroy(): void {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
        this.dialogViewModelCreatorSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.subscription = this.deactivateMessageService.deactivateOldCustomMessage(this.message.data);

        this.isDeactivated = this.message.data.deactivated !== undefined ? this.message.data.deactivated : false;

        this.isExplanationOpen = this.message.data.isExplanationOpen;

        this.responseItemData = this.message.data.responseItemData as ExplanationResponseItemData;
        this.hasExplanation = this.checkExplanation();
        this.hasMedia = this.responseItemData.message.media !== undefined && Object.keys(this.responseItemData.message.media).length > 0;
        this.text = this.responseItemDataTextService.create<ExplanationResponseItemText>(this.responseItemData.text);

        if (!this.hasMedia) {
            this.componentRenderingCompleted();
        }
    }

    private checkExplanation(): boolean {
        const haveExplanation = this.responseItemData.message.explanations !== undefined && this.responseItemData.message.explanations.filter(element => element !== '').length > 0;
        const haveMedia = this.responseItemData.message.media.mediaType !== undefined;
        return haveExplanation || haveMedia;
    }

    public shouldShowDetails(): boolean {
        return !this.responseItemData.message.answerIsCorrect || this.isExplanationOpen;
    }

    public createExplanationTextMessage(): string[] {
        const correctAnswerMessage = !this.responseItemData.message.answerIsCorrect &&
        this.responseItemData.correctAnswer.length > 0 ? this.responseItemData.text.answerInformation : '';

        const explanations: string[] = this.responseItemData.message.explanations.map(explanation =>
            explanation.split(' || ')).reduce((a, b) => a.concat(b), []);

        const mainExplanationMessage = this.getMainExplanationMessage(explanations, correctAnswerMessage);

        const additionalExplanationMessages: string[] = [];

        if (explanations.length > 1) {
            explanations.shift()
            for (const explanation of explanations) {
                additionalExplanationMessages.push(this.responseItemDataTextService.replaceText(explanation));
            }
        }

        return [mainExplanationMessage, ...additionalExplanationMessages];
    }

    private getMainExplanationMessage(explanations: string[], correctAnswerMessage: string): string {
        if (explanations.length === 0) {
            return this.responseItemDataTextService.replaceText(correctAnswerMessage);
        }
        if (correctAnswerMessage.length > 0) {
            return `${this.responseItemDataTextService.replaceText(correctAnswerMessage)} ${this.responseItemDataTextService.replaceText(explanations[0])}`;

        }
        return this.responseItemDataTextService.replaceText(explanations[0]);
    }

    public sendNextQuestion(): void {
        void this.botatoChatManagerService.sendUserMessage(this.responseItemData.text.nextQuizButtonText, 'quickreply');
    }

    public openExplanation(): void {
        this.isExplanationOpen = true;
        this.dialogViewModelCreatorSubscription = this.dialogViewModelCreatorService.getDialogViewModelObservable().pipe(take(1)).subscribe((dialogViewModel) => {
            for (const [index, lastMessages] of dialogViewModel.messageGroups.entries()) {
                if (index === dialogViewModel.messageGroups.length - 1) {
                    for (const lastMessage of lastMessages.messages) {
                        if (lastMessage.type === ExplanationCardComponent.TYPE) {

                            lastMessage.data.isExplanationOpen = true;
                        }
                    }
                }
            }
        });
    }

    public override onAnyMessageSent(): void {
        if (!this.isDeactivated) {

            this.message.data.deactivated = true;
        }
    }

    public mediaLoaded(): void {
        this.componentRenderingCompleted();
    }
}
