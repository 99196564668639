$chat-quickreply-border-size: 1px !default;
$chat-quickreply-hover-shadow-size: 2px !default;
$chat-quickreply-box-shadow: none !default;

.pa-quickreplies {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: center;
    margin-left: $chat-message-margin-left;
    margin-top: $chat-message-margin;

    .pa-quickreply {
        display: block;
        flex-shrink: 0;
        font-size: $chat-message-font-size;
        cursor: pointer;

        background-color: $bot-quickreply-bg-color;
        color: $bot-quickreply-color;
        border: solid $chat-quickreply-border-size $bot-quickreply-border-color;
        box-shadow: $chat-quickreply-box-shadow;
        border-radius: $message-border-radius;
        padding: $chat-message-padding-vertical - $chat-quickreply-border-size $bot-quickreply-horizontal-padding;
        margin-right: $chat-message-margin;
        margin-bottom: $chat-message-margin;
        min-height: $chat-message-height;
        max-width: 100%;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;

        &:hover {
            background-color: $bot-quickreply-hover-bg-color;
            box-shadow: 0 0 $chat-quickreply-hover-shadow-size;
            color: $bot-quickreply-focus-color;
        }

        &:focus {
            outline: 0;
            background-color: $bot-quickreply-focus-bg-color;
            color: $bot-quickreply-focus-color;
        }

    }
}
